<app-dialog-wrapper
  [cancelButtonText]="'Cancel'"
  [saveButtonText]="'Create'"
  (cancelClick)="onCancelClick()"
  (saveClick)="onSaveClick()"
  [saveDisabled]="formInvalid"
  [saveButtonTestId]="testIds.ProjectDialogSaveButton"
  [cancelButtonTestId]="testIds.ProjectDialogCancelButton"
  saveButtonIcon="basic-checkmark"
>
  <div [testId]="testIds.ProjectDialogTitle" class="headlines-h3 greyscale-darkest-grey" title-projection>
    New Project
  </div>
  <form class="px-56 py-24 flex-column" flexGap="16px" [formGroup]="form">
    <div class="flex-row" flexGap="16px">
      <div class="flex-column flex">
        <ngx-q360-text-input
          [testId]="testIds.ProjectDialogNameInput"
          placeholder="Project name"
          label="Project name"
          [formControl]="controls.name"
          inputType="input"
          [error]="controls.name.touched && controls.name.invalid"
        />
        <ngx-q360-input-message [control]="controls.name" />
      </div>

      <div class="flex-column">
        <ngx-q360-text-input
          [testId]="testIds.ProjectDialogShortNameInput"
          placeholder="2-5 characters"
          label="Short name"
          [formControl]="controls.code"
          inputType="input"
          [error]="controls.code.touched && controls.code.invalid"
        />
        <ngx-q360-input-message [control]="controls.code" />
      </div>
    </div>

    @if (data.selectedMainProject && myOrgIsCoOwner) {
      @if (projectHasOwner) {
        <mat-divider />
        <div class="flex-row" flexGap="4px">
          <ngx-q360-avatar
            [avatar]="(data.selectedMainProject.admins || [] | filter: data.selectedMainProject.ownerOrganizationId)[0]"
            [sasUrl]="sasUrl()"
          />

          {{
            (data.selectedMainProject.admins || [] | filter: data.selectedMainProject.ownerOrganizationId).length
              ? (data.selectedMainProject.admins || [] | filter: data.selectedMainProject.ownerOrganizationId)[0]
                  .organizationName
              : ''
          }}
        </div>

        <div class="flex-column">
          <ngx-q360-select-input
            [sasUrl]="sasUrl()"
            [testId]="testIds.ProjectProjectLeaderInput"
            [withSearch]="true"
            placeholder="Enter email or search"
            label="Project leader on behalf of the client"
            [formControl]="controls.projectLeaderId"
            inputType="input"
            [error]="controls.projectLeaderId.touched && controls.projectLeaderId.invalid"
            [items]="users"
          />
          <ngx-q360-input-message [control]="controls.projectLeaderId" />
        </div>

        <mat-divider />
      }

      <div class="flex-row" flexGap="4px">
        <ngx-q360-avatar [sasUrl]="sasUrl()" [avatar]="{ photoUrl: myOrg()?.photoUrl, name: myOrg()?.name }" />
        {{ myOrg()?.name }}
      </div>

      <!--if main project does not have an owner/client, the organisation creating a project must be a collaborator in the resulting project-->
      @if (!projectHasOwner) {
        <div [testId]="testIds.ProjectCollaboratorInfo" class="p-16 secondary-8-blue-8-bg br-8 flex-column">
          <div class="flex-row" flexGap="8px">
            <svg class="primary-colors-accent-blue svg-16" svgIcon="framed-info" />
            <div class="flex-column" flexGap="8px">
              <div class="headlines-subtitle-all-caps greyscale-darkest-grey" [ngStyle]="{ opacity: 0.48 }">
                You must Collaborate on this project.
              </div>
              <div class="labels-label-m greyscale-darkest-grey">
                If you invite an owner to the main project group, that organization will become the client of the
                project you are creating, and you will not be required to collaborate.
              </div>
            </div>
          </div>
        </div>
      }

      <mat-slide-toggle
        [testId]="testIds.ProjectCollaboratorToggle"
        #collaborating
        (change)="onCollaborationChange($event)"
        [disabled]="!projectHasOwner"
        [checked]="!projectHasOwner"
      >
        My organization is collaborating on this project
      </mat-slide-toggle>

      @if (collaborating.checked) {
        <div class="flex-column">
          <ngx-q360-select-input
            [sasUrl]="sasUrl()"
            [testId]="testIds.ProjectRoleInput"
            placeholder="Select a role"
            label="Your organization’s role"
            [formControl]="controls.collaboratorType"
            inputType="input"
            [error]="controls.collaboratorType.touched && controls.collaboratorType.invalid"
            [items]="collaboratorTypes"
          />
          <ngx-q360-input-message [control]="controls.collaboratorType" />
        </div>
      } @else {
        <div [testId]="testIds.ProjectNoAccessInfo" class="p-16 secondary-8-orange-8-bg br-8 flex-column">
          <div class="flex-row" flexGap="8px">
            <div>
              <svg class="primary-colors-orange svg-16" svgIcon="framed-alert-triangle" />
            </div>
            <div class="flex-column" flexGap="8px">
              <div class="headlines-subtitle-all-caps greyscale-darkest-grey" [ngStyle]="{ opacity: 0.48 }">
                You won’t have access to the project.
              </div>
              <div class="labels-label-m greyscale-darkest-grey">
                If you change your mind, another organization on the project will have to invite you as a collaborator.
              </div>
            </div>
          </div>
        </div>
      }

      @if (collaborating.checked) {
        <mat-divider />

        <div class="headlines-subtitle1 greyscale-darkest-grey">Contract Conditions</div>

        <div class="flex-row" flexGap="16px" flexLayoutAlign="start center">
          <div class="flex-row greyscale-background-grey-bg p-8 br-4 arrow-box right" flexGap="4px">
            @if (projectHasOwner) {
              <ngx-q360-avatar
                [sasUrl]="sasUrl()"
                class="flex-align-self-start"
                size="small"
                [avatar]="
                  (data.selectedMainProject.admins || [] | filter: data.selectedMainProject.ownerOrganizationId)[0]
                "
              />
            } @else {
              <svg class="svg-16 greyscale-idle-grey" svgIcon="framed-help-questionmark" />
            }
            <div class="flex-column" flexGap="4px">
              <div class="labels-label-m">
                {{
                  projectHasOwner
                    ? (data.selectedMainProject.admins || [] | filter: data.selectedMainProject.ownerOrganizationId)
                        .length
                      ? (data.selectedMainProject.admins || []
                          | filter: data.selectedMainProject.ownerOrganizationId)[0].organizationName
                      : ''
                    : 'Unknown organization'
                }}
              </div>
              <div class="labels-label-s">Client</div>
            </div>
          </div>

          <svg class="svg-24" svgIcon="documents-file-filled" />

          <div class="flex-row greyscale-background-grey-bg p-8 br-4 arrow-box left" flexGap="4px">
            <ngx-q360-avatar
              [sasUrl]="sasUrl()"
              class="flex-align-self-start"
              size="small"
              [avatar]="{ photoUrl: myOrg()?.photoUrl, name: myOrg()?.name }"
            />
            <div class="flex-column" flexGap="4px">
              <div class="labels-label-m">{{ myOrg()?.name }}</div>
              <div class="labels-label-s">
                {{ controls.collaboratorType.value ? controls.collaboratorType.value : 'Role not selected' }}
              </div>
            </div>
          </div>
        </div>

        <div class="labels-label-l greyscale-body-text-grey">
          @if (projectHasOwner) {
            These conditions specify the contract between your organization and the client.
          } @else {
            These conditions specify the contract between your organization and the client who may be invited to the
            project later.
          }
        </div>

        <div class="flex-column">
          <ngx-q360-select-input
            [sasUrl]="sasUrl()"
            [testId]="testIds.ProjectContractConditionsInput"
            placeholder="Select contract conditions"
            label="Contract conditions "
            [formControl]="contractType"
            inputType="input"
            [error]="contractType.touched && contractType.invalid"
            [items]="contractTypes"
          />
          <ngx-q360-input-message [control]="contractType" />
        </div>
      }

      @if (collaborating.checked && contractType.value === 'unknown') {
        <div class="flex-column flex">
          <ngx-q360-text-input
            [testId]="testIds.ProjectContractConditionsTitleInput"
            placeholder="Contract conditions title"
            label="Contract conditions title"
            [formControl]="contractConditionTitle"
            inputType="input"
            [error]="contractConditionTitle.touched && contractConditionTitle.invalid"
          />
          <ngx-q360-input-message [control]="contractConditionTitle" />
        </div>
      }
    }
  </form>
</app-dialog-wrapper>
